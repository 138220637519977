@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";


.slick-arrow:before, 
.slick-next:before{
  color: black;
  width: 70px;
  display: block;
}

.slick-next:before {
  margin-left: -50px;
}
.slick-arrow {
  z-index: 999;
}

.fade-in {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.fade-in.visible {
  opacity: 1;
}

.contact-form {
  background-color: green;
  height: 90vh;
  margin: -100px;
  overflow-y: scroll;
}
  